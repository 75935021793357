@import url('style/GlobalStyle.css');

.dataGridElement{
    cursor: pointer;
    max-height: auto;
}

.tagChip{
    color:white;
    margin:2px;
    padding:3px 6px;
    background-color: var(--button-primary);
    border-radius: 3px;
}

.tagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:4px;
    margin:5px auto;
    min-height: fit-content;
    overflow-x: scroll;
}
.deleteModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.openInNewIcon {
    padding-bottom: 6px;
    vertical-align: middle;
}

.overFlowTitleText {
    overflow-wrap: anywhere;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.formSubBlock{
    margin-top:10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.iconContainer{
    font-size: 25px;
}

.iconContainerp{
    margin-left: 5px;
}

.btnContainerActions{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.submitBtnContainer {
    flex-direction: revert;
    justify-content: flex-end;
    margin: 10px auto;
    align-items: center;
    display: flex;
    grid-gap: 20px;
    gap: 5px;
}

.commentArea {
    border: 1px solid silver;
    padding: 9px;
    outline: 0;
    font-size: 14px;
    border-radius: 8px;
    height: 150px;
    width: 100%;
    resize: none;
}

.headerContainerComment {
    display: flex;
    margin-bottom: 10px;
}

.commentIconHeader {
    margin-right: 5px;
}

.pLabels {
    color: rgb(107, 114, 128);
}

.btnContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
}

.autocomplete-disabled {
    color: grey;
    background-color: #f0f0f0;
    pointer-events: none;
}
.tagContainer{
    overflow-y: auto;
}
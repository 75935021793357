@import '../../../style/GlobalStyle.css';

.title {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.textContainer {
  margin-top: 5px;
  margin-bottom: 20px;
}

.textBrief {
  margin-left: 20px;
}

.textBrief li {
  word-break: break-word;
}

.textBriefNew {
  margin-left: 20px;
  max-height: 100px;
  overflow-y:scroll;
}
.preformatted {
  background: #eee;
  padding: 1rem;
  overflow: auto;
  border-radius: 3px;
}

.preformatted,
.codeContainer {
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: break-word;
  line-height: 1.5;
}

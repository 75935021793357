.autoCompleteSelection{
    margin-top:5px;
    margin-bottom: 10px;
}

.formSubBlock{
    margin-top:10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.btnSubmit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.tagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:4px;
    margin:5px auto;
    min-height: fit-content;
}
.tagChip{
    color:white;
    margin:2px;
    padding:3px 6px;
    background-color: var(--button-primary);
    border-radius: 3px;
}
.tagChipAction {
    text-decoration: none;
    color: white;
}
.tagChipDelete {
    text-decoration: none;
    color:white;
    padding-left:5px;
    padding-right:5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: small;
    color: #feca37;
    border-radius:50%;border: 1px solid #feca37;
    margin-top:5px;
    margin-bottom:5px;
}

.commentArea{
    border: 1px solid silver;
    padding: 9px;
    outline: 0;
    font-size: 14px;
    border-radius: 8px;
    height: 150px;
    width: 100%;
    resize: none;
}

.headerContainerComment{
    display: flex;
    margin-bottom: 10px;
}

.commentIconHeader{
    margin-right: 5px;
}

.pLabels{
    color: rgb(107, 114, 128);
}

.btnContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
}
.autocomplete-disabled {
    color: grey;
    background-color: #f0f0f0;
    pointer-events: none;
  }
  

.userHeader {
    display: flex;
    flex: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .userHeaderTitle {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .manageUserButton {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .isAdmin,
  .userEmail {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .saveButton {
    display: flex;
    justify-content: flex-end;
  }
  
  .addUserTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  @media only screen and (max-width: 851px) {
    .userHeader{
      gap: 8px;
    }
  }
  .deleteUserContainer {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-direction: column;
    align-items: center;
  }
  
  .deleteBtnContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
  }

  .tooltipIcons{
    font-size: 15px;
    margin-right: 2px;
  }

.tooltipContainer{
  display: flex;
  align-items: center;
}

.export-container{
  float: right;
}

.disabled-button {
  color: gray;
  pointer-events: none;
}

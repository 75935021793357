.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    /* border-top: 1px solid grey; */
    padding: 15px 40px;
    margin-top: 15px;
  }
  
  .labelInput {
    font-size: 1rem;
    min-width: 15rem;
  }
  
  .autoCompleteInput {
    min-width: 480px;
  }
  
  .inputTextfield {
    min-width: 300px;
  }
  
  .updateForm {
    margin:20px 30px;
    height:400px;
    overflow-y: scroll;
  }


.btnContainer {
  display: flex;
  justify-content: flex-end;
  gap:15px;
  align-items: center;
  margin-top: 15x;
  padding-right: 40px;
}